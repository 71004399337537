$('document').ready(function(){

    var mobile = $(window).width() < 768;

    if(!mobile){
        var tmpImg = new Image();
        tmpImg.src = $('.main_picture-wrapper img').attr('src');
        tmpImg.onload = function() {
            var the_image = $(".main_picture-wrapper img");

            var imageW = the_image[0].naturalWidth;
            var imageH = (400/imageW) * the_image[0].naturalHeight;

            $(".main_picture-wrapper img").height(imageH).show();
        } ;
    }

});